import P2PListPage, { getServerSideProps as getServerSidePropsP2P } from '@/pages/p2p/index.instantwire'
import gssp from '@/utils/gssp'

const Index = () => <P2PListPage />

export default Index

export const getServerSideProps = gssp((context) => {
  return getServerSidePropsP2P(context)
})
