import dynamic from 'next/dynamic'

import { MainLayout } from '@/layouts/index'
import gssp from '@/utils/gssp'

const P2POfferList = dynamic(() => import('@/components/p2p-offer-list'), { ssr: false })

const P2PMarketPage = () => {
  return (
    <MainLayout>
      <P2POfferList />
    </MainLayout>
  )
}

export default P2PMarketPage

export const getServerSideProps = gssp()
